/**
 * --------------
 * ---- DTOs ----
 * --------------
 *
 */

const GLOBAL_SEARCH = (function () {
    /**
     * -----------------
     * ---- CONSTANTS --
     * -----------------
     */

    function Group(name, items, code) {
        this.header = name;
        this.items = items;
        this.code = code;
    }

    const ITEM_TYPE = {
        PLACE: "PLACE",
        TEXT: "TEXT",
        USER: "USER",
    };

    function Item(id, name, htmlName, context, iconUrl, url, type, sw, ne) {
        this.id = id;
        this.name = name;
        this.htmlName = htmlName;
        this.iconUrl = iconUrl;
        this.context = context;
        this.url = url;
        this.type = type;
        this.sw = sw;
        this.ne = ne;
    }

    /**
     * This list contains all the elements with the class 'S' that are allowed in our system. This
     * allowed codes are related to our pictograms
     *
     * @see "https://www.geonames.org/export/codes.html"
     */
    const ALLOWED_SPOTS_GEOCODER = [
        "RKRY",
        "BDG",
        "CSTL",
        "MN",
        "MNMT",
        "PKLT",
        "RHSE",
        "ANS",
        "RLG",
        "SPA",
        "RUIN",
        "OBPT",
        "CAVE",
        "HSTS",
    ];
    /**
     * In meters. The radius of the future bbox
     * TIP. Leaflet zoom, each zoom is half or double size than the previous one
     * @type {{TNLRD: number, COVE: number, MNFE: number, ISLF: number, ADM5H: number, ISLM: number, STM: number, ISLT: number, TMPL: number, ISLS: number, TNL: number, BCHS: number, BUSTN: number, BUSTP: number, TNLRR: number, CMN: number, BCH: number, CMP: number, ADM4H: number, MNMT: number, RHSE: number, OBPT: number, PASS: number, ADMDH: number, BDG: number, RLG: number, PPL: number, PPLA: number, PPLC: number, TNLN: number, RSTP: number, RSTN: number, PPLG: number, PPLF: number, ISL: number, RKRY: number, CTRR: number, ATHF: number, LK: number, RET: number, STMIX: number, RLGR: number, SPA: number, PRK: number, MTS: number, RUIN: number, STMB: number, STMC: number, STMA: number, COUNTRY: number, PCLI: number, STMH: number, STMI: number, UNKN: number, RSTNQ: number, STMD: number, MN: number, TNLS: number, MNQR: number, MT: number, PPLX: number, MUS: number, MNAU: number, RGN: number, PKS: number, TREE: number, ISLET: number, MNA: number, MNC: number, ANS: number, ADM3H: number, MNN: number, PPLA2: number, MNQ: number, PPLA3: number, PPLA4: number, FY: number, ADM2H: number, FLLSX: number, RSTPQ: number, FYT: number, STMQ: number, STMSB: number, ADMD: number, PKLT: number, STMM: number, STMX: number, FLLS: number, RDIN: number, ADM4: number, MNCR: number, ADM1H: number, ADM5: number, ADM2: number, ADM3: number, CSTL: number, MNCU: number, ADM1: number, PK: number}}
     */
    const DISTANCE_GEONAMES = {
        //ZoomLevel (based on the values in the equator
        RGN: 100000, //7
        ADM1: 100000, //7
        ADM1H: 100000, //7
        ADM2: 12500, //10
        ADM2H: 12500, //10
        ADM3: 5000, //12
        ADM3H: 5000, //12
        ADM4: 5000, //12
        ADM4H: 5000, //12
        ADM5: 5000, //12
        ADM5H: 5000, //12
        ADMD: 5000, //12
        ADMDH: 5000, //12
        COUNTRY: 200000, //6
        PCLI: 200000, //6
        PPLC: 12500, //10
        PPLA: 7500, //11
        PPLA2: 5000, //12
        PPLA3: 5000, //12
        PPLA4: 2500, //13
        PPLF: 2500, //13
        PPLG: 2500, //13
        PPL: 2500, //13
        PPLX: 750, //15
        ISL: 12500, //10
        ISLET: 12500, //10
        ISLF: 12500, //10
        ISLM: 12500, //10
        ISLS: 12500, //10
        ISLT: 12500, //10
        MTS: 5000, //12
        MT: 1250, //14
        PK: 1250, //14
        PKS: 1250, //14
        STM: 1250, //14
        STMA: 1250, //14
        STMB: 1250, //14
        STMC: 1250, //14
        STMD: 1250, //14
        STMH: 1250, //14
        STMI: 1250, //14
        STMIX: 1250, //14
        STMM: 1250, //14
        STMQ: 1250, //14
        STMSB: 1250, //14
        STMX: 1250, //14
        LK: 1250, //14
        COVE: 1250, //14
        FLLS: 1250, //14
        FLLSX: 1250, //14
        TNL: 300, //16
        TNLN: 300, //16
        TNLRD: 300, //16
        TNLRR: 300, //16
        TNLS: 300, //16
        MNA: 300, //16
        CMN: 300, //16
        PRK: 300, //16
        BCH: 300, //16
        BCHS: 300, //16
        PASS: 300, //16
        RKRY: 300, //16
        BDG: 300, //16
        MUS: 300, //16
        BUSTN: 300, //16
        BUSTP: 300, //16
        CMP: 300, //16
        CSTL: 300, //16
        FY: 300, //16
        FYT: 300, //16
        RDIN: 300, //16
        MN: 300, //16
        MNAU: 300, //16
        MNC: 300, //16
        MNCR: 300, //16
        MNCU: 300, //16
        MNFE: 300, //16
        MNMT: 300, //16
        MNN: 300, //16
        MNQ: 300, //16
        MNQR: 300, //16
        PKLT: 300, //16
        RET: 300, //16
        RHSE: 300, //16
        ANS: 300, //16
        CTRR: 300, //16
        RLG: 300, //16
        RLGR: 300, //16
        TMPL: 300, //16
        SPA: 300, //16
        RSTN: 300, //16
        RSTNQ: 300, //16
        RSTP: 300, //16
        RSTPQ: 300, //16
        RUIN: 300, //16
        ATHF: 300, //16
        OBPT: 300, //16
        TREE: 300, //16
        UNKN: 300, //16
    };

    /* List of geocodes excluded for road planner */
    const GEONAMES_EXCLUDED = ["ADM1", "ADM2", "ADM3"];

    /**
     * Map generated from ftp.unicode.org/Public/UNIDATA/UnicodeData.txt. Maps each
     * upper-case basic alphabet character in the A-Z range to a regex character
     * class that contains all variations (accented, unaccented, upper-case,
     * lower-case) of this character (e.g. A->[AaÃ„Ã¤Ã¥....]).
     **/
    const CHAR_TO_ACCENTED_CHAR_CLASS_MAP = {
        A: "[Aa\xaa\xc0-\xc5\xe0-\xe5\u0100-\u0105\u01cd\u01ce\u0200-\u0203\u0226\u0227\u1d2c\u1d43\u1e00\u1e01\u1e9a\u1ea0-\u1ea3\u2090\u2100\u2101\u213b\u249c\u24b6\u24d0\u3371-\u3374\u3380-\u3384\u3388\u3389\u33a9-\u33af\u33c2\u33ca\u33df\u33ff\uff21\uff41]",
        B: "[Bb\u1d2e\u1d47\u1e02-\u1e07\u212c\u249d\u24b7\u24d1\u3374\u3385-\u3387\u33c3\u33c8\u33d4\u33dd\uff22\uff42]",
        C: "[Cc\xc7\xe7\u0106-\u010d\u1d9c\u2100\u2102\u2103\u2105\u2106\u212d\u216d\u217d\u249e\u24b8\u24d2\u3376\u3388\u3389\u339d\u33a0\u33a4\u33c4-\u33c7\uff23\uff43]",
        D: "[Dd\u010e\u010f\u01c4-\u01c6\u01f1-\u01f3\u1d30\u1d48\u1e0a-\u1e13\u2145\u2146\u216e\u217e\u249f\u24b9\u24d3\u32cf\u3372\u3377-\u3379\u3397\u33ad-\u33af\u33c5\u33c8\uff24\uff44]",
        E: "[Ee\xc8-\xcb\xe8-\xeb\u0112-\u011b\u0204-\u0207\u0228\u0229\u1d31\u1d49\u1e18-\u1e1b\u1eb8-\u1ebd\u2091\u2121\u212f\u2130\u2147\u24a0\u24ba\u24d4\u3250\u32cd\u32ce\uff25\uff45]",
        F: "[Ff\u1da0\u1e1e\u1e1f\u2109\u2131\u213b\u24a1\u24bb\u24d5\u338a-\u338c\u3399\ufb00-\ufb04\uff26\uff46]",
        G: "[Gg\u011c-\u0123\u01e6\u01e7\u01f4\u01f5\u1d33\u1d4d\u1e20\u1e21\u210a\u24a2\u24bc\u24d6\u32cc\u32cd\u3387\u338d-\u338f\u3393\u33ac\u33c6\u33c9\u33d2\u33ff\uff27\uff47]",
        H: "[Hh\u0124\u0125\u021e\u021f\u02b0\u1d34\u1e22-\u1e2b\u1e96\u210b-\u210e\u24a3\u24bd\u24d7\u32cc\u3371\u3390-\u3394\u33ca\u33cb\u33d7\uff28\uff48]",
        I: "[Ii\xcc-\xcf\xec-\xef\u0128-\u0130\u0132\u0133\u01cf\u01d0\u0208-\u020b\u1d35\u1d62\u1e2c\u1e2d\u1ec8-\u1ecb\u2071\u2110\u2111\u2139\u2148\u2160-\u2163\u2165-\u2168\u216a\u216b\u2170-\u2173\u2175-\u2178\u217a\u217b\u24a4\u24be\u24d8\u337a\u33cc\u33d5\ufb01\ufb03\uff29\uff49]",
        J: "[Jj\u0132-\u0135\u01c7-\u01cc\u01f0\u02b2\u1d36\u2149\u24a5\u24bf\u24d9\u2c7c\uff2a\uff4a]",
        K: "[Kk\u0136\u0137\u01e8\u01e9\u1d37\u1d4f\u1e30-\u1e35\u212a\u24a6\u24c0\u24da\u3384\u3385\u3389\u338f\u3391\u3398\u339e\u33a2\u33a6\u33aa\u33b8\u33be\u33c0\u33c6\u33cd-\u33cf\uff2b\uff4b]",
        L: "[Ll\u0139-\u0140\u01c7-\u01c9\u02e1\u1d38\u1e36\u1e37\u1e3a-\u1e3d\u2112\u2113\u2121\u216c\u217c\u24a7\u24c1\u24db\u32cf\u3388\u3389\u33d0-\u33d3\u33d5\u33d6\u33ff\ufb02\ufb04\uff2c\uff4c]",
        M: "[Mm\u1d39\u1d50\u1e3e-\u1e43\u2120\u2122\u2133\u216f\u217f\u24a8\u24c2\u24dc\u3377-\u3379\u3383\u3386\u338e\u3392\u3396\u3399-\u33a8\u33ab\u33b3\u33b7\u33b9\u33bd\u33bf\u33c1\u33c2\u33ce\u33d0\u33d4-\u33d6\u33d8\u33d9\u33de\u33df\uff2d\uff4d]",
        N: "[Nn\xd1\xf1\u0143-\u0149\u01ca-\u01cc\u01f8\u01f9\u1d3a\u1e44-\u1e4b\u207f\u2115\u2116\u24a9\u24c3\u24dd\u3381\u338b\u339a\u33b1\u33b5\u33bb\u33cc\u33d1\uff2e\uff4e]",
        O: "[Oo\xba\xd2-\xd6\xf2-\xf6\u014c-\u0151\u01a0\u01a1\u01d1\u01d2\u01ea\u01eb\u020c-\u020f\u022e\u022f\u1d3c\u1d52\u1ecc-\u1ecf\u2092\u2105\u2116\u2134\u24aa\u24c4\u24de\u3375\u33c7\u33d2\u33d6\uff2f\uff4f]",
        P: "[Pp\u1d3e\u1d56\u1e54-\u1e57\u2119\u24ab\u24c5\u24df\u3250\u3371\u3376\u3380\u338a\u33a9-\u33ac\u33b0\u33b4\u33ba\u33cb\u33d7-\u33da\uff30\uff50]",
        Q: "[Qq\u211a\u24ac\u24c6\u24e0\u33c3\uff31\uff51]",
        R: "[Rr\u0154-\u0159\u0210-\u0213\u02b3\u1d3f\u1d63\u1e58-\u1e5b\u1e5e\u1e5f\u20a8\u211b-\u211d\u24ad\u24c7\u24e1\u32cd\u3374\u33ad-\u33af\u33da\u33db\uff32\uff52]",
        S: "[Ss\u015a-\u0161\u017f\u0218\u0219\u02e2\u1e60-\u1e63\u20a8\u2101\u2120\u24ae\u24c8\u24e2\u33a7\u33a8\u33ae-\u33b3\u33db\u33dc\ufb06\uff33\uff53]",
        T: "[Tt\u0162-\u0165\u021a\u021b\u1d40\u1d57\u1e6a-\u1e71\u1e97\u2121\u2122\u24af\u24c9\u24e3\u3250\u32cf\u3394\u33cf\ufb05\ufb06\uff34\uff54]",
        U: "[Uu\xd9-\xdc\xf9-\xfc\u0168-\u0173\u01af\u01b0\u01d3\u01d4\u0214-\u0217\u1d41\u1d58\u1d64\u1e72-\u1e77\u1ee4-\u1ee7\u2106\u24b0\u24ca\u24e4\u3373\u337a\uff35\uff55]",
        V: "[Vv\u1d5b\u1d65\u1e7c-\u1e7f\u2163-\u2167\u2173-\u2177\u24b1\u24cb\u24e5\u2c7d\u32ce\u3375\u33b4-\u33b9\u33dc\u33de\uff36\uff56]",
        W: "[Ww\u0174\u0175\u02b7\u1d42\u1e80-\u1e89\u1e98\u24b2\u24cc\u24e6\u33ba-\u33bf\u33dd\uff37\uff57]",
        X: "[Xx\u02e3\u1e8a-\u1e8d\u2093\u213b\u2168-\u216b\u2178-\u217b\u24b3\u24cd\u24e7\u33d3\uff38\uff58]",
        Y: "[Yy\xdd\xfd\xff\u0176-\u0178\u0232\u0233\u02b8\u1e8e\u1e8f\u1e99\u1ef2-\u1ef9\u24b4\u24ce\u24e8\u33c9\uff39\uff59]",
        Z: "[Zz\u0179-\u017e\u01f1-\u01f3\u1dbb\u1e90-\u1e95\u2124\u2128\u24b5\u24cf\u24e9\u3390-\u3394\uff3a\uff5a]",
    };

    /**
     * -------------------------
     * ------- METHODS ---------
     * -------------------------
     */

    /**
     * Represents the current XHR (XMLHttpRequest) request.
     *
     * @type {XMLHttpRequest|null}
     */
    let currentXhrRequest = null;

    /**
     * Makes an asynchronous GET request to the specified URL and executes the callback function with the response data.
     * If an error occurs during the request or parsing of the response, the error callback function is executed.
     *
     * @param {string} url - The URL to send the GET request to.
     * @param {function} callback - The function to execute when the request is successful. It receives the parsed response data as an argument.
     * @param {function} error - The function to execute when an error occurs during the request or parsing of the response.
     *                          It does not receive any arguments.
     */
    function ajax_get(url, callback, error) {
        if (currentXhrRequest) {
            currentXhrRequest.abort();
        }
        currentXhrRequest = new XMLHttpRequest();
        currentXhrRequest.onreadystatechange = function () {
            if (currentXhrRequest.readyState === 4) {
                if (
                    currentXhrRequest.status === 200 &&
                    currentXhrRequest.responseText
                ) {
                    try {
                        var data = JSON.parse(currentXhrRequest.responseText);
                    } catch (err) {
                        console.log(
                            err.message +
                                " in " +
                                currentXhrRequest.responseText
                        );
                        error && error();
                    }
                    callback(data);
                } else if (currentXhrRequest.status !== 200) {
                    error && error();
                }
            }
        };

        currentXhrRequest.open("GET", url, true);
        currentXhrRequest.send();
    }

    /**
     * Get list of Places from Raw JSON geocode search places result
     * @param queryText: text of search
     * @param response: RAW JSON geocode search place result
     * return List of Places
     */
    function processGeocoderResponse(
        queryText,
        response,
        i18n,
        isRoutePlanner
    ) {
        //1- Group the result in countries
        var geoname;
        var countries = {}; //key: "country name", value: "array of geoname JSON objects"
        var result = [];

        for (var i = 0; i < response.geonames.length; i++) {
            geoname = response.geonames[i];
            var name = geoname.name;
            var toponymName = geoname.toponymName;
            var adminName = geoname.adminName1;

            var countryName = geoname.countryName;
            var isAValidSpot = true;
            var fclass = geoname.fcl;
            if (fclass === "S") {
                isAValidSpot =
                    ALLOWED_SPOTS_GEOCODER.indexOf(geoname.fcode) > -1;
            }

            if (isAValidSpot && geoname.fcode !== "PPLX") {
                if (countries[countryName] == null) {
                    if (countryName == null) {
                        countryName = i18n.txtOtherPlaces;
                    }
                    countries[countryName] = {};
                }

                if (fclass !== "P" && fclass !== "A") {
                    if (
                        toponymName !== "" &&
                        !countries[countryName][
                            fclass +
                                "," +
                                name +
                                "," +
                                toponymName +
                                "," +
                                adminName
                        ]
                    ) {
                        countries[countryName][
                            fclass +
                                "," +
                                name +
                                "," +
                                toponymName +
                                "," +
                                adminName
                        ] = geoname;
                    } else if (
                        !countries[countryName][
                            fclass + "," + name + "," + adminName
                        ]
                    ) {
                        countries[countryName][
                            fclass + "," + name + "," + adminName
                        ] = geoname;
                    }
                } else {
                    if (
                        !countries[countryName][
                            fclass + "," + name + "," + adminName
                        ]
                    ) {
                        countries[countryName][
                            fclass + "," + name + "," + adminName
                        ] = geoname;
                    }
                }
            }
        }

        //2- Generate DTOs
        var queryTextUpper =
            queryText.charAt(0).toUpperCase() + queryText.slice(1);
        var highlightRegexp = _createAccentRegexp(queryTextUpper);

        for (let countryKey in countries) {
            result.push(
                _factoryGroupFromCountry(
                    countryKey,
                    countries[countryKey],
                    highlightRegexp,
                    isRoutePlanner
                )
            );
        }

        //3- Add current user country as first result
        var requestCountryCode = response.countryCode;
        if (requestCountryCode != null) {
            result.sort(function (x, y) {
                return x.code == requestCountryCode
                    ? -1
                    : y.code == requestCountryCode
                      ? 1
                      : 0;
            });
        }

        return result;
    }

    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     */

    /**
     * Creates a regexp for case- and accent-insensitive matching of a regular
     * alphabetic string. For each character in the original string the output
     * contains a regexp character class that matches the character itself and all
     * unicode variations. So, if the input is "Foo" the function returns
     * something like [Ff...][OoÃ–Ã¶Ã’Ã²..][OoÃ–Ã¶Ã’Ã²..].
     **/
    function _createAccentRegexp(characters) {
        // Replaces all accented characters.
        var deaccentedString = _deaccent(characters);
        // Escapes all regexp meta characters.
        var cleanString = deaccentedString.replace(
            /([|()[{.+*?^$\\])/g,
            "\\$1"
        );
        var accentReplacer = function (character) {
            return CHAR_TO_ACCENTED_CHAR_CLASS_MAP[character] || character;
        };
        // Matches anything *but* a whitespace and replaces it.
        var regexp = "(" + cleanString.replace(/\S/g, accentReplacer) + ")";
        return new RegExp(regexp, "ig");
    }

    /**
     * Returns a string in which each accented and lower-case character from the
     * input is replaced with the respective upper-case base character in the A-Z
     * range (e.g. Ã¤->A, Ã¨->E, Ã¥->A, Ã«->E). Hence, the return value for
     * "sÃ©lÃ©ction" is "SELECTION".
     **/
    function _deaccent(accentedString) {
        var result = accentedString;
        for (var key in CHAR_TO_ACCENTED_CHAR_CLASS_MAP) {
            result = result.replace(
                new RegExp(CHAR_TO_ACCENTED_CHAR_CLASS_MAP[key], "g"),
                key
            );
        }
        return result;
    }

    /**
     * Factory for creating Groups DTOs from countries
     */
    function _factoryGroupFromCountry(
        countryName,
        country,
        highlightRegexp,
        isRoutePlanner
    ) {
        var itemsDTO = [];
        var countryCode = "";

        for (let placeName in country) {
            let place = country[placeName];
            if (place.countryCode) {
                countryCode = place.countryCode;
            }

            if (!isRoutePlanner || !GEONAMES_EXCLUDED.includes(place.fcode)) {
                var itemDTO = _factoryItemFromPlace(place, highlightRegexp);
                itemsDTO.push(itemDTO);
            }
        }

        if (itemsDTO.length !== 0) {
            var groupDTO = new Group(countryName, itemsDTO, countryCode);
            return groupDTO;
        }
        return {};
    }

    /**
     * Factory for creating Items DTOs from places
     * @param place
     */
    function _factoryItemFromPlace(place, highlightRegexp) {
        var id = place.id;
        var name = place.name;
        var htmlName = name.replace(highlightRegexp, "<strong>$1</strong>");
        var context = place.adminName1;
        if (
            place.fcl !== "P" &&
            place.fcl !== "A" &&
            place.toponymName !== ""
        ) {
            if (place.toponymName !== name) {
                context = place.toponymName;
                if (place.adminName1) {
                    context += ", " + place.adminName1;
                }
            }
        }
        var bbox = place.bbox;
        var sw = null;
        var ne = null;
        if (bbox != null) {
            sw = bbox.south + "," + bbox.west;
            ne = bbox.north + "," + bbox.east;
        } else {
            var inferedDistanceMeters = _inferDistanceMetersFromGeocoderFCode(
                place.fcode
            );
            bbox = getBoundingBox(
                parseFloat(place.lat),
                parseFloat(place.lng),
                inferedDistanceMeters
            );
            sw = bbox[2];
            ne = bbox[1];
        }

        //URL
        var url =
            "/wikiloc/map.do?sw=" +
            sw +
            "&ne=" +
            ne +
            "&page=1" +
            "&place=" +
            encodeURIComponent(name);

        //Icon
        var iconUrl = _getIconUrl(place);
        var placeDTO = new Item(
            id,
            name,
            htmlName,
            context,
            iconUrl,
            url,
            ITEM_TYPE.PLACE,
            sw,
            ne
        );
        return placeDTO;
    }

    /**
     * Get a bounding box from a point and a distance
     * @param pLatitude
     * @param pLongitude
     * @param pDistanceInMeters radius
     * @return {*[]}
     * @note: https://stackoverflow.com/questions/2839533/adding-distance-to-a-gps-coordinate
     * @private
     */
    function getBoundingBox(pLatitude, pLongitude, pDistanceInMeters) {
        var latRadian = _toRad(pLatitude);

        var degLatKm = 110.574235;
        var degLongKm = 110.572833 * Math.cos(latRadian);
        var deltaLat = pDistanceInMeters / 1000.0 / degLatKm;
        var deltaLong = pDistanceInMeters / 1000.0 / degLongKm;

        var topLat = pLatitude + deltaLat;
        var bottomLat = pLatitude - deltaLat;
        var leftLng = pLongitude - deltaLong;
        var rightLng = pLongitude + deltaLong;

        var northWestCoords = topLat + "," + leftLng;
        var northEastCoords = topLat + "," + rightLng;
        var southWestCoords = bottomLat + "," + leftLng;
        var southEastCoords = bottomLat + "," + rightLng;

        var boundingBox = [
            northWestCoords,
            northEastCoords,
            southWestCoords,
            southEastCoords,
        ];

        return boundingBox;
    }

    /**
     * Converts a number to radiants
     * @private
     */
    function _toRad(number) {
        return (number * Math.PI) / 180;
    }

    function _inferDistanceMetersFromGeocoderFCode(fcode) {
        var distanceMeters = DISTANCE_GEONAMES[fcode];
        if (typeof distanceMeters === "undefined") {
            distanceMeters = 300;
        }
        return distanceMeters;
    }

    /**
     * Get icon url for this place
     * Remember to update `_isAnOldIcon` and `_migrateOldIcon` with the old images
     */
    function _getIconUrl(place) {
        var fcl = place.fcl;
        var fcode = place.fcode;
        if (fcl === "T") {
            if (["MT", "MTS", "PK", "PKS"].indexOf(fcode) > -1) {
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/33.svg";
            } else if (fcode === "BCH" || fcode === "BCHS") {
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/97.svg";
            } else if (fcode === "PASS") {
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/94.svg";
            }
        } else if (fcl === "P") {
            return "https://sc.wklcdn.com/wikiloc/assets/styles/images/geocoder/geocoder_city.svg";
        } else if (fcl === "H") {
            var matchRiver = fcode.match(/STM*/g);
            if (fcode === "LK") {
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/71.svg";
            } else if (matchRiver && matchRiver.length > 0) {
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/72.svg";
            } else if (fcode === "FLLS" || fcode === "FLLSX") {
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/91.svg";
            } else if (fcode === "SPNG") {
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/5.svg";
            }
        } else if (fcl === "V") {
            if (fcode === "TREE") {
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/34.svg";
            }
            return "https://sc.wklcdn.com/wikiloc/assets/styles/images/geocoder/geocoder_valley_forest.svg";
        } else if (fcl === "A" || fcl === "L") {
            if (fcode === "MNA") {
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/103.svg";
            } else if (fcode === "CMN" || fcode === "PRK") {
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/93.svg";
            } else if (fcode === "RESN") {
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/geocoder/geocoder_valley_forest.svg";
            }
            return "https://sc.wklcdn.com/wikiloc/assets/styles/images/geocoder/geocoder_area.svg";
        } else if (fcl === "R") {
            if (
                fcode === "TNL" ||
                fcode === "TNLN" ||
                fcode === "TNLRD" ||
                fcode === "TNLRR" ||
                fcode === "TNLS"
            ) {
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/131.svg";
            }
        } else if (fcl === "S") {
            switch (fcode) {
                case "RKRY":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/119.svg";
                case "BDG":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/73.svg";
                case "MUS":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/123.svg";
                case "BUSTN":
                case "BUSTP":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/127.svg";
                case "CMP":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/23.svg";
                case "CSTL":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/100.svg";
                case "FY":
                case "FYT":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/129.svg";
                case "RDIN":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/74.svg";
                case "MN":
                case "MNAU":
                case "MNC":
                case "MNCR":
                case "MNCU":
                case "MNFE":
                case "MNN":
                case "MNQ":
                case "MNQR":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/103.svg";
                case "PKLT":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/32.svg";
                case "RET":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/99.svg";
                case "ANS":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/35.svg";
                case "CTRR":
                case "RLG":
                case "RLGR":
                case "TMPL":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/104.svg";
                case "SPA":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/124.svg";
                case "RSTN":
                case "RSTNQ":
                case "RSTP":
                case "RSTPQ":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/128.svg";
                case "RUIN":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/96.svg";
                case "ATHF":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/130.svg";
                case "OBPT":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/10.svg";
                case "UNKN":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/geocoder/geocoder_marker.svg";
                case "CAVE":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/102.svg";
                case "MNMT":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/125.svg";
                case "RHSE":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/31.svg";
                case "HSTS":
                    return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/123.svg";
            }
        }
        return "https://sc.wklcdn.com/wikiloc/assets/styles/images/geocoder/geocoder_marker.svg";
    }

    /**
     *    Public methods used globally on Vue components
     **/
    return {
        METHODS: {
            ajax_get: ajax_get,
            processGeocoderResponse: processGeocoderResponse,
            getBoundingBox: getBoundingBox,
        },
        DTOS: {
            Item: Item,
            Group: Group,
            ITEM_TYPE: ITEM_TYPE,
        },
    };
})();
